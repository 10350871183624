import { useEffect, useRef } from "react";

import { useSession } from "next-auth/react";

import { instance } from "~engine/axiosInstance";
import { getAbsoluteDate } from "~lib/time";

import { Mixpanel } from "@external/Mixpanel";

export interface CommonWrapperProps {
  children: JSX.Element;
}
export default function CommonWrapper({ children }: CommonWrapperProps) {
  const sessionExistsAndRan = useRef(false);
  const { data: session } = useSession();
  const token = session?.sessionToken;
  instance.defaults.headers.common["token"] = token;

  useEffect(() => {
    if (!session) return;
    Mixpanel.alias(session.id);
    Mixpanel.identify(session.id);
    Mixpanel.people.set({
      $distinct_id: session.id,
      $name: session.user.name,
      $email: session.user.email,
      $user_uid: session.id,
      $avatar: session.user.image,
      $loginAt: getAbsoluteDate(new Date().getTime()),
    });

    sessionExistsAndRan.current = true;
  }, [session]);

  return <>{children}</>;
}
