import { create } from "zustand";
import { persist } from "zustand/middleware";

interface UidStore {
  groupUid: string;
  setGroupUid: (groupUid: string) => void;
  userUid: string;
  setUserUid: (userUid: string) => void;
}

export const useUidStore = create<UidStore>()(
  persist(
    (set) => ({
      groupUid: "",
      setGroupUid: (groupUid) => set({ groupUid }),
      userUid: "",
      setUserUid: (userUid) => set({ userUid }),
    }),
    {
      name: "uidStore",
    }
  )
);
