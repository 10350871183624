import React, { useEffect, useState } from "react";

import { NextPage } from "next";
import { SessionProvider } from "next-auth/react";
import { appWithTranslation } from "next-i18next";
import { DefaultSeo } from "next-seo";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import Script from "next/script";

import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Hydrate, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "dayjs/locale/ko";
import "prismjs/themes/prism-tomorrow.css";
import ReactGA from "react-ga4";
import "react-toastify/dist/ReactToastify.css";
import CommonWrapper from "v10/common/CommonWrapper";

import { queryClient as globalQueryClient } from "~engine/queryClient";

import { ErrorBoundary } from "containers/common/ErrorBoundary";
import ErrorPage from "containers/common/ErrorPage";
// import { NotificationProvider } from "libs/stores/notificationStore";
import "styles/globals.css";

import { Mixpanel } from "@external/Mixpanel";
import { RouterTransition } from "@external/RouterTransition";
import { emotionCache, mantineTheme } from "@external/mantine";
import { nextSeoConfig } from "@external/next-seo.config";

const ToastContainer = dynamic(() =>
  import("react-toastify").then((mod) => mod.ToastContainer)
);

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactElement;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout & { auth?: boolean };
};

function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
  const [queryClient] = useState(() => globalQueryClient);
  const router = useRouter();

  const getLayout = Component.getLayout || ((page) => <>{page}</>);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize("G-RNR324W3NS");
    }

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/sw.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope:",
            registration.scope
          );
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    }
  }, []);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      Mixpanel.track("PageView");
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
      });
    }
  }, [router]);

  return (
    <SessionProvider session={session}>
      <QueryClientProvider client={queryClient}>
        <MantineProvider
          theme={mantineTheme}
          withNormalizeCSS
          withGlobalStyles
          emotionCache={emotionCache}
        >
          <ModalsProvider labels={{ confirm: "확인", cancel: "취소" }}>
            <ErrorBoundary fallback={<ErrorPage />}>
              {/* <NotificationProvider> */}
              <RouterTransition />
              <Hydrate state={pageProps.dehydratedState}>
                <DefaultSeo {...nextSeoConfig} />
                <CommonWrapper>
                  {getLayout(<Component {...pageProps} />)}
                </CommonWrapper>
              </Hydrate>
              {/* </NotificationProvider> */}
            </ErrorBoundary>
            <ToastContainer
              position="top-right"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick={false}
              rtl={false}
              draggable={false}
              pauseOnFocusLoss={false}
              pauseOnHover={true}
            />
          </ModalsProvider>
        </MantineProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      <Script src="https://cdn.iamport.kr/v1/iamport.js" />
      <Script
        async
        onError={(e) => {
          console.error("Script failed to load", e);
        }}
        strategy="afterInteractive"
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8015781611019902"
        crossOrigin="anonymous"
      />
    </SessionProvider>
  );
}

export default appWithTranslation(App);
