import { ErrorProps } from "next/error";
import Image from "next/image";
import { useRouter } from "next/router";

import { Button } from "@mantine/core";
import { shallow } from "zustand/shallow";

import { useUidStore } from "libs/stores/uidStore";

export interface ErrorPageProps {
  text?: React.ReactNode;
  withRefresh?: boolean;
  error?: ErrorProps;
}

export default function ErrorPage({
  text = (
    <>
      오류가 발생했어요.
      <br />
      잠시 후에 다시 시도 해주세요.
    </>
  ),
  withRefresh = false,
  error,
}: ErrorPageProps) {
  const router = useRouter();

  const { setGroupUid, setUserUid } = useUidStore(
    (state) => ({
      setGroupUid: state.setGroupUid,
      setUserUid: state.setUserUid,
    }),
    shallow
  );

  console.log("ERROR", error);

  return (
    <div className="m-auto mt-32 flex flex-col items-center justify-center gap-5 text-center">
      <Image
        src="/images/character/sorry.png"
        alt="sorry"
        width="151"
        height="182"
      />
      <span>{text}</span>
      <div className="flex gap-2">
        <Button
          variant="outline"
          onClick={async () => {
            await setGroupUid("");
            await setUserUid("");
            await router.back();
            await setTimeout(() => {
              router.reload();
            }, 100);
          }}
        >
          이전 페이지로 이동
        </Button>
        {withRefresh ? (
          <Button
            onClick={async () => {
              await setGroupUid("");
              await setUserUid("");
              await router.reload();
            }}
          >
            새로고침
          </Button>
        ) : (
          <Button
            onClick={async () => {
              await setGroupUid("");
              await setUserUid("");
              await router.push(`/`);
              await setTimeout(() => {
                router.reload();
              }, 100);
            }}
          >
            메인 페이지로 이동
          </Button>
        )}
      </div>
    </div>
  );
}
