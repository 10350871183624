import axios from "axios";
import qs from "qs";

export const instance = axios.create({
  paramsSerializer: {
    serialize: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  },
});
