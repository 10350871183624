export function getCurrentDateFormatted() {
  let date = new Date();
  let year = date.getFullYear();
  let month = String(date.getMonth() + 1).padStart(2, "0"); // pad with zero if single digit
  let day = String(date.getDate()).padStart(2, "0"); // pad with zero if single digit

  return `${year}${month}${day}`;
}

export const getAbsoluteDate = (timestamp: number) => {
  return new Intl.DateTimeFormat("ko", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(
    timestamp.toFixed(0).toString().length === 10 ? timestamp * 1000 : timestamp
  );
};

export const getRelativeDate = (timestamp: number) => {
  return new Intl.RelativeTimeFormat("ko", {
    numeric: "auto",
  }).format(
    Math.round(
      ((timestamp.toFixed(0).toString().length === 10
        ? timestamp * 1000
        : timestamp) -
        new Date().getTime()) /
        1000 / // milleseconds => seconds
        60 / // seconds => minutes
        60 / // minutes => hours
        24 // hours => days
    ),
    "days"
  );
};

export const getLongDatetime = (timestamp: number) => {
  return new Intl.DateTimeFormat("ko", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  }).format(
    timestamp.toFixed(0).toString().length === 10 ? timestamp * 1000 : timestamp
  );
};

export const getShortDatetime = (timestamp: number, isRelative = false) => {
  // timestamp의 날짜가 오늘이라면 시간만 표시
  if (
    new Date(
      timestamp.toFixed(0).toString().length === 10
        ? timestamp * 1000
        : timestamp
    ).toDateString() === new Date().toDateString()
  ) {
    if (isRelative) {
      return new Intl.RelativeTimeFormat("ko", {
        numeric: "auto",
      }).format(
        Math.round(
          ((timestamp.toFixed(0).toString().length === 10
            ? timestamp * 1000
            : timestamp) -
            new Date().getTime()) /
            1000 / // milleseconds => seconds
            60 // seconds => minutes
        ),
        "minutes"
      );
    } else {
      return new Intl.DateTimeFormat("ko", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      }).format(
        timestamp.toFixed(0).toString().length === 10
          ? timestamp * 1000
          : timestamp
      );
    }
  } else {
    return new Intl.DateTimeFormat("en", {
      month: "2-digit",
      day: "2-digit",
    }).format(
      timestamp.toFixed(0).toString().length === 10
        ? timestamp * 1000
        : timestamp
    );
  }
};
