import { DefaultSeoProps } from "next-seo";

const ogImage = "/images/ogImage.png";

export const nextSeoConfig: DefaultSeoProps = {
  titleTemplate: "%s | 코어닷투데이",
  defaultTitle: "코어닷투데이",
  description: "다가올 AI 시대, AI 협업 플랫폼 코어닷투데이에서 준비하세요!",
  canonical: "https://core.today/",
  openGraph: {
    type: "website",
    locale: "ko_kr",
    url: "https://core.today/",
    images: [
      {
        url: ogImage,
        width: 1200,
        height: 630,
        alt: "코어닷투데이",
      },
    ],
  },
  twitter: {
    handle: "@handle",
    site: "@site",
    cardType: "summary_large_image",
  },
};
