import {
  MantineColor,
  MantineThemeOverride,
  createEmotionCache,
} from "@mantine/core";

export type MantineColorOverride = MantineColor | "primary" | "secondary";

export const emotionCache = createEmotionCache({
  key: "mantine",
});

export const mantineTheme: MantineThemeOverride = {
  globalStyles: (theme) => ({
    body: {
      // ...theme.fn.fontStyles(),
      fontFamily: "Noto Sans KR, sans-serif",
    },
  }),
  fontFamily: "Noto Sans KR, sans-serif",
  breakpoints: {
    xs: "30em", // 480px
    sm: "36em", // 576px
    md: "48em", // 768px
    lg: "64em", // 1024px
    xl: "80em", // 1280px
    "2xl": "90em", // 1440px
    "3xl": "110em", // 1760px
    "4xl": "160em", // 2560px 4k`
  },
  colors: {
    primary: [
      "#e2eeff",
      "#b6cbfd",
      "#88a8f6",
      "#5a85f1",
      "#2d62ec",
      "#1648d3",
      "#0e38a5",
      "#072877",
      "#01184a",
      "#00081e",
    ],
    secondary: [
      "#d7fdff",
      "#aaf2ff",
      "#7ae8ff",
      "#48deff",
      "#1ad4ff",
      "#00bbe6",
      "#0091b4",
      "#006882",
      "#003f50",
      "#00171f",
    ],
    point: [
      "#ffe5e5",
      "#fabcbb",
      "#f09290",
      "#e86663",
      "#e03c38",
      "#c7231f",
      "#9c1a17",
      "#6f1010",
      "#450807",
      "#1e0000",
    ],
    slate: [
      "#f8fafc",
      "#f1f5f9",
      "#e2e8f0",
      "#cbd5e1",
      "#94a3b8",
      "#64748b",
      "#475569",
      "#334155",
      "#1e293b",
      "#0f172a",
    ],
  },
  loader: "dots",
  respectReducedMotion: false,
  cursorType: "pointer",
  components: {
    ActionIcon: {
      defaultProps: {
        radius: "xl",
        color: "dark",
      },
    },
    Avatar: {
      defaultProps: {
        radius: "xl",
      },
    },
    DatePicker: {
      defaultProps: {
        locale: "ko-kr",
        firstDayOfWeek: "sunday",
        inputFormat: "YY.MM.DD",
        labelFormat: "YYYY월 M월",
      },
    },
    DateRangePicker: {
      defaultProps: {
        locale: "ko-kr",
        firstDayOfWeek: "sunday",
        inputFormat: "YY.MM.DD",
        labelFormat: "YYYY월 M월",
      },
    },
    TextInput: {
      styles: {
        label: {
          fontWeight: 600,
        },
      },
    },
    Textarea: {
      styles: {
        label: {
          fontWeight: 600,
        },
      },
    },
    Menu: {
      defaultProps: {
        position: "bottom-end",
        shadow: "xl",
      },
    },
    Modal: {
      defaultProps: {
        size: "auto",
        centered: true,
      },
      styles: {
        title: {
          fontWeight: 700,
          fontSize: "20px",
          width: "100%",
          lineHeight: "normal",
        },
        content: {
          borderRadius: "15px",
          margin: "0 auto",
          minWidth: "300px",
        },
      },
    },
    Drawer: {
      styles: {
        content: {
          margin: "0",
        },
      },
    },
    Popover: {
      defaultProps: {
        position: "bottom-end",
        shadow: "xl",
      },
    },
  },
};
